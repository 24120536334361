import React from 'react';
import ReferFriendForm from './refer-friend-form';
import styles from './refer-friend-form-section.module.scss';

export default () => {
  return (
    <div className={`columns ${styles.centeredBox}`}>
      <div className={`column is-6 is-10-mobile ${styles.columnWithPadding}`}>
        <div className={styles.limit}>
          <h3 className={`title is-4 ${styles.title}`}>Refer a friend</h3>
          <ReferFriendForm />
        </div>
      </div>
    </div>
  );
};
