import React from 'react';
import { graphql } from 'gatsby';
import { Helmet } from 'react-helmet';
import Layout from '../../components/layout';
import HeroImage from '../../components/hero-image/hero-image';
import { GatsbyImg } from '../../services/img-location/img-location-service';
import ReferralBonusSection from '../../components/trustaff-traveler/refer-friend/referral-bonus-section';
import ReferFriendForm from '../../components/trustaff-traveler/refer-friend/refer-friend-form-section';
import styles from './refer-friend.module.scss';

export default ({ data }) => {
  const gatsbyOgImg = new GatsbyImg('refer-friend-og', data);
  const gatsbyHeroImg = new GatsbyImg('hero-image', data);
  const gatsbyReferralBonusImg = new GatsbyImg('referral-bonus', data);

  return (
    <>
      <Helmet>
        <title>Refer a friend - Trustaff</title>
        <meta charSet="utf-8" />
        <meta
          name="Description"
          content="If you have a friend, coworker, or a family member interested in finding out more about the great opportunities available through Trustaff, start by completing the form below."
        />
        <meta property="og:title" content="Refer a friend" />
        <meta
          property="og:description"
          content="If you have a friend, coworker, or a family member interested in finding out more about the great opportunities available through Trustaff, start by completing the form below."
        />
        <meta property="og:image" content={gatsbyOgImg.ogImgUrl} />
        <meta
          property="og:url"
          content="https://www.trustaff.com/trustaff-traveler/refer-friend/"
        />
        <meta property="og:locale" content="en_US" />
        <meta property="og:site_name" content="Trustaff" />
        <meta property="og:type" content="website" />
        <meta name="twitter:site" content="@Trustaff" />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
      <Layout>
        <HeroImage fluid={gatsbyHeroImg.fluidImg}>
          <div className={'columns'}>
            <div className="column is-6 is-offset-1">
              <div
                className={`${styles.heroTextArea} has-text-centered-mobile`}
              >
                <h1 className={`title is-3 has-text-white`}>
                  Earn referral bonuses
                </h1>
                <p className={`content`}>
                  Many of our best Trustaff travelers have found their way to us
                  through a kind word from a friend or colleague, and we'll
                  reward you for the good deed.
                </p>
                <p className={`content`}>
                  If you have a friend, coworker, or a family member interested
                  in finding out more about the great opportunities available
                  through Trustaff, start by completing the form below.
                </p>
              </div>
            </div>
          </div>
        </HeroImage>
        <div className={`section ${styles.sectionNoSidePaddingMobile}`}>
          <ReferralBonusSection fluid={gatsbyReferralBonusImg.fluidImg} />
        </div>
        <section
          className={`section ${styles.sectionNoSidePaddingMobile} ${styles.vertical}`}
        >
          <ReferFriendForm />
        </section>
      </Layout>
    </>
  );
};

export const pageQuery = graphql`
  {
    allFile(filter: { relativePath: { regex: "/^trustaff-traveler/" } }) {
      edges {
        node {
          name
          childImageSharp {
            fluid(maxWidth: 2400) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`;
