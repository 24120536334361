import { navigate } from '@reach/router';

import apiService from '../services/api-service';
import referrerDataService from './referrer-data-service';

const ReferFriendService = {
  sendReferFriendMessage: sendReferFriendMessage,
};

function sendReferFriendMessage(formData) {
  formData.referrerUrl = referrerDataService.getSavedReferrer();
  formData.utmParams = referrerDataService.getSavedUtmData();

  const REFER_FRIEND_PATH = '/api/staff/referfriend';

  apiService
    .post(REFER_FRIEND_PATH, formData)
    .then(resp => handleFormSubmissionSuccess(resp))
    .catch(err => console.log('An error occurred', err));
}

function handleFormSubmissionSuccess(response) {
  navigate(`/trustaff-traveler/refer-friend-success-confirmation`);
}

export default ReferFriendService;
