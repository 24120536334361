import React from 'react';
import Img from 'gatsby-image';
import styles from './referral-bonus-section.module.scss';

export default ({ fluid }) => {
  return (
    <div className="columns">
      <div
        className={`columns column is-12 is-10-desktop is-offset-1-desktop has-text-centered-mobile`}
      >
        <div className="column is-7">
          <h3 className="title is-5 has-text-info">
            Want to help a friend find their dream job? So do we
          </h3>
          <div className="content">
            <p className="content">
              There’s no limit to the number of referrals you can complete—and
              you don’t even have to be a current Trustaff employee to earn a
              bonus. Referral amounts vary by the referred person's area of
              expertise.
            </p>
            <div className="columns content">
              <ul className={`${styles.bonusColumnLeft} column is-10`}>
                <li>
                  Registered Nurse in OH or CA | <b>$1,000</b>
                </li>
                <li>
                  Registered Nurse | <b>$750</b>
                </li>
                <li>
                  LPN | <b>$350</b>
                </li>
                <hr />
                <li>
                  Pathology Assistant or Perfusionist | <b>$1,000</b>
                </li>
                <li>
                  PT, OT, or SLP | <b>$1,000</b>
                </li>
                <li>
                  Respiratory Therapist | <b>$750</b>
                </li>
                <li>
                  Cath Lab Tech, Ultrasound Tech, or Echo Tech | <b>$750</b>
                </li>
                <li>
                  Med Tech or Med Lab Tech | <b>$750</b>
                </li>
                <li>
                  COTA or PTA | <b>$750</b>
                </li>
                <li>
                  Other Allied Health Professional | <b>$500</b>
                </li>
              </ul>
            </div>
            <p className="content">
              Trustaff pays you within 30 days of the start of the assignment
              whereas other agencies pay upon completion.
            </p>
          </div>
        </div>
        <div className={`column is-4 is-offset-1 is-hidden-mobile`}>
          <Img
            fluid={fluid}
            className={styles.aboutImageWrapper}
            alt="travel nursing benefits"
          />
        </div>
      </div>
    </div>
  );
};
