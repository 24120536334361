import React, { useState } from 'react';
import { useFormik } from 'formik';

import Input from '../../form-components/input';
import TelephoneInput from '../../form-components/telephone-input';
import ReferFriendFormValidationService from './refer-friend-form-validation-helper';
import ReferFriendService from './../../../services/refer-friend-service';
import styles from './refer-friend-form.module.scss';

const ReferFriendForm = () => {
  const [isLoading, setIsLoading] = useState(false);

  const validate = ReferFriendFormValidationService.getValidatorFunc();

  const formik = useFormik({
    initialValues: {
      referrerName: '',
      referrerEmail: '',
      referrerPhoneNumber: '',
      referrerRecruiterName: '',
      friendName: '',
      friendEmail: '',
      friendPhoneNumber: '',
      friendProfessionSpecialty: '',
    },
    validate,
    onSubmit: values => {
      setIsLoading(true);
      ReferFriendService.sendReferFriendMessage(values);
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="field">
        <Input
          id="referrerName"
          name="referrerName"
          placeholder="Your name"
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          value={formik.values.referrerName}
          errorMessage={
            formik.touched.referrerName && formik.errors.referrerName
              ? formik.errors.referrerName
              : null
          }
          className="is-rounded"
          errorClass={styles.lightErrorMsg}
        />
      </div>

      <div className="field">
        <Input
          id="referrerEmail"
          name="referrerEmail"
          type="email"
          placeholder="Your email"
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          value={formik.values.referrerEmail}
          errorMessage={
            formik.touched.referrerEmail && formik.errors.referrerEmail
              ? formik.errors.referrerEmail
              : null
          }
          className="is-rounded"
          errorClass={styles.lightErrorMsg}
        />
      </div>
      <div className="field">
        <TelephoneInput
          id="referrerPhoneNumber"
          name="referrerPhoneNumber"
          placeholder="Your phone number"
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          value={formik.values.referrerPhoneNumber}
          errorMessage={
            formik.touched.referrerPhoneNumber &&
            formik.errors.referrerPhoneNumber
              ? formik.errors.referrerPhoneNumber
              : null
          }
          errorClass={styles.lightErrorMsg}
          className="is-rounded"
        />
      </div>

      <div className="field">
        <Input
          id="referrerRecruiterName"
          name="referrerRecruiterName"
          placeholder="Your recruiter’s name"
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          value={formik.values.referrerRecruiterName}
          errorMessage={
            formik.touched.referrerRecruiterName &&
            formik.errors.referrerRecruiterName
              ? formik.errors.referrerRecruiterName
              : null
          }
          className="is-rounded"
          errorClass={styles.lightErrorMsg}
        />
      </div>

      <div className={`${styles.formDivider}`}></div>

      <div className={`field`}>
        <Input
          id="friendName"
          name="friendName"
          placeholder="Friend’s name"
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          value={formik.values.friendName}
          errorMessage={
            formik.touched.friendName && formik.errors.friendName
              ? formik.errors.friendName
              : null
          }
          className="is-rounded"
          errorClass={styles.lightErrorMsg}
        />
      </div>

      <div className="field">
        <Input
          id="friendEmail"
          name="friendEmail"
          type="email"
          placeholder="Friend’s email"
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          value={formik.values.friendEmail}
          errorMessage={
            formik.touched.friendEmail && formik.errors.friendEmail
              ? formik.errors.friendEmail
              : null
          }
          className="is-rounded"
          errorClass={styles.lightErrorMsg}
        />
      </div>
      <div className="field">
        <TelephoneInput
          id="friendPhoneNumber"
          name="friendPhoneNumber"
          placeholder="Friend’s phone"
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          value={formik.values.friendPhoneNumber}
          errorMessage={
            formik.touched.friendPhoneNumber && formik.errors.friendPhoneNumber
              ? formik.errors.friendPhoneNumber
              : null
          }
          errorClass={styles.lightErrorMsg}
          className="is-rounded"
        />
      </div>

      <div className="field">
        <Input
          id="friendProfessionSpecialty"
          name="friendProfessionSpecialty"
          placeholder="Friend’s profession/specialty"
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          value={formik.values.friendProfessionSpecialty}
          errorMessage={
            formik.touched.friendProfessionSpecialty &&
            formik.errors.friendProfessionSpecialty
              ? formik.errors.friendProfessionSpecialty
              : null
          }
          className="is-rounded"
          errorClass={styles.lightErrorMsg}
        />
      </div>

      <div className={`has-text-centered field ${styles.submitButtonWrapper}`}>
        <button
          type="submit"
          className={`button is-info ${styles.submitButton} ${
            isLoading ? 'is-loading disabled' : ''
          }`}
        >
          Submit
        </button>
      </div>
    </form>
  );
};

export default ReferFriendForm;
