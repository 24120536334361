import {
  isValidEmail,
  isValidTelephoneNumber,
} from '../../../utils/validation-utils';

const validationHelper = {
  getValidatorFunc: getValidatorFunc,
};

const NAME_MAX_LENGTH = 30;
const PROFESSION_SPECIALTY_MAX_LENGTH = 40;

function getValidatorFunc(namesOfFieldsOnForm) {
  return formValues => {
    let errors = {};

    if (!formValues.referrerName) {
      errors.referrerName = 'Name is required';
    } else if (formValues.referrerName.length > NAME_MAX_LENGTH) {
      errors.referrerName = `Must be ${NAME_MAX_LENGTH} characters or less`;
    }

    if (!formValues.referrerEmail) {
      errors.referrerEmail = 'Required';
    } else if (!isValidEmail(formValues.referrerEmail)) {
      errors.referrerEmail = 'Invalid email address';
    }

    if (!formValues.referrerPhoneNumber) {
      errors.referrerPhoneNumber = 'Required';
    } else if (!isValidTelephoneNumber(formValues.referrerPhoneNumber)) {
      errors.referrerPhoneNumber = 'Invalid phone number';
    }

    if (formValues.referrerRecruiterName.length > NAME_MAX_LENGTH) {
      errors.referrerRecruiterName = `Must be ${NAME_MAX_LENGTH} characters or less`;
    }

    if (!formValues.friendName) {
      errors.friendName = 'Name is required';
    } else if (formValues.friendName.length > NAME_MAX_LENGTH) {
      errors.friendName = `Must be ${NAME_MAX_LENGTH} characters or less`;
    }

    if (
      formValues.friendEmail.length !== 0 &&
      !isValidEmail(formValues.friendEmail)
    ) {
      errors.friendEmail = 'Invalid email address';
    }

    if (!formValues.friendPhoneNumber) {
      errors.friendPhoneNumber = 'Required';
    } else if (!isValidTelephoneNumber(formValues.friendPhoneNumber)) {
      errors.friendPhoneNumber = 'Invalid phone number';
    }

    if (
      formValues.friendProfessionSpecialty.length >
      PROFESSION_SPECIALTY_MAX_LENGTH
    ) {
      errors.friendProfessionSpecialty = `Must be ${PROFESSION_SPECIALTY_MAX_LENGTH} characters or less`;
    }

    return errors;
  };
}

export default validationHelper;
